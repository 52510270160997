<template>
  <div :class="$style.home_notice">
    <base-tab tab-color="underline" :tab="current_tab" @tab="tabChange">
      <template v-slot:トーク>
        <div :class="$style.team_tab_content">
          <div :class="$style.team_item_list">
            <div
              v-for="(notification, i) of talk_notifications"
              :class="$style.team_item_wrap"
              :key="i"
            >
              <template>
                <app-carousel-comment-notification-item-wide
                  :border-color="
                    notification.team_color
                      ? `rgb(${notification.team_color})`
                      : '#7BE293'
                  "
                  :head="notification.title"
                  :teamName="notification.team_name"
                  :time="notification.datetime"
                  :image="
                    notification.image_url
                      ? notification.image_url
                      : 'https://via.placeholder.com/140x50'
                  "
                  @click="
                    goTo(notification, notification.chat_type, notification.id)
                  "
                  :icon="notification.block_user_flg ? '' : 'heart'"
                  :selected="checkSelectable(notification)"
                  :goodCount="notification.good_count"
                  :goodFlg="!!notification.good_flg"
                  :readFlg="!!notification.read_flg"
                  :readCount="notification.read_count"
                  :commentCount="notification.comment_count"
                  @like="clickGood(notification, notification.id)"
                  @commentLike="commentFavorite(notification)"
                  @commentReplyLike="commentReplyFavorite(notification)"
                  :backgroundColor="notification.team_color"
                  :commentType="notification.comment_type"
                  :commentText="notification.comment_text"
                  :commentReplyType="notification.comment_reply_type"
                  :commentReplyText="notification.comment_reply_text"
                  :chatDate="notification.chat_date"
                  :replyTargetId="notification.reply_target_id"
                  :replyTargetText="notification.reply_target_text"
                  :replyTargetImage="notification.reply_target_image"
                  :replyTargetCreateUserName="notification.reply_target_create_user_name"
                  :replyTargetCreateUserImage="notification.reply_target_create_user_image"
                  :replyTargetMentionedUsers="notification.reply_target_mentioned_users"
                  :replyTargetDate="notification.reply_target_date"
                  :nickName="notification.nickname"
                  :isMentioned="notification.mention_target_flg"
                  :type="notification.type"
                  :customTagColor="'#2DCCD3'"
                  :customTagTextColor="'#ffffff'"
                  :category="notification.category"
                  :userId="notification.created_by"
                  :post="notification"
                  :avatarImage="notification.profile_image_url"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:連携サービスからのお知らせ>
        <div :class="$style.notice_tab_content">
          <div :class="$style.notice_item_list">
            <div
              v-for="(notification, i) of sso_notifications"
              :class="$style.notice_item_wrap"
              :key="i"
            >
              <app-carousel-comment-notification-item-wide
                :border-color="
                  notification.team_color
                    ? `rgb(${notification.team_color})`
                    : '#7BE293'
                "
                :head="
                  notification.comment_latest_text
                    ? notification.comment_latest_text
                    : notification.title
                "
                :teamName="notification.team_name"
                :time="notification.created_at"
                :image="
                  notification.image_url
                    ? notification.image_url
                    : 'https://via.placeholder.com/140x50'
                "
                @click="
                  goTo(notification, notification.type, notification.chat_id)
                "
                :icon="notification.block_user_flg ? '' : 'heart'"
                :selected="checkSelectable(notification)"
                :goodCount="notification.goods_count"
                :goodFlg="!!notification.good_flg"
                :readFlg="!!notification.read_flg"
                :readCount="notification.read_count"
                :commentCount="notification.comment_count"
                @like="clickGoods(notification, notification.chat_id)"
                :backgroundColor="notification.team_color"
                :commentText="notification.comment_text"
                :nickName="notification.chat_user_nickname"
                :isMentioned="notification.mention_target_flg"
                :customTagColor="'#2DCCD3'"
                :customTagTextColor="'#ffffff'"
                :category="notification.category"
                :userId="notification.created_by"
                :serviceId="notification.service_id"
                :post="notification"
                :avatarImage="notification.profile_path"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:チームからのお知らせ>
        <div :class="$style.team_tab_content">
          <div :class="$style.team_item_list">
            <div
              v-for="(notification, i) of team_notifications"
              :class="$style.team_item_wrap"
              :key="i"
            >
              <template
                v-if="
                  ['chat_comment', 'mini_vote_comment'].includes(
                    notification.category
                  )
                "
              >
                <app-carousel-comment-notification-item
                  :border-color="
                    notification.team_color
                      ? `rgb(${notification.team_color})`
                      : '#7BE293'
                  "
                  :head="notification.title"
                  :teamName="notification.team_name"
                  :time="notification.datetime"
                  :image="
                    notification.image_url
                      ? notification.image_url
                      : 'https://via.placeholder.com/140x50'
                  "
                  @click="toChatComment(notification)"
                  :icon="notification.block_user_flg ? '' : 'heart'"
                  :selected="checkSelectable(notification)"
                  @select="good(notification)"
                  :backgroundColor="notification.team_color"
                  :commentType="notification.comment_type"
                  :commentText="notification.comment_text"
                  :nickName="notification.nickname"
                  :isMentioned="notification.mention_target_flg"
                  :isRead="notification.notification_confirm_flg"
                  :userTeamStatus="notification.user_team_status"
                />
              </template>
              <template v-else>
                <app-carousel-post-notification-item
                  :border-color="
                    notification.team_color
                      ? `rgb(${notification.team_color})`
                      : '#7BE293'
                  "
                  :head="notification.title"
                  :teamName="notification.team_name"
                  :time="notification.datetime"
                  :image="
                    notification.image_url
                      ? notification.image_url
                      : 'https://via.placeholder.com/140x50'
                  "
                  @click="toTeamPage(notification)"
                  :icon="notification.block_user_flg ? '' : 'heart'"
                  :isRead="notification.notification_confirm_flg"
                  :selected="checkSelectable(notification)"
                  @select="good(notification)"
                  :userTeamStatus="notification.user_team_status"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:アプリからのお知らせ>
        <div :class="$style.notice_tab_content">
          <div :class="$style.notice_item_list">
            <div
              v-for="(notification, i) of news_notifications"
              :class="$style.notice_item_wrap"
              :key="i"
            >
              <app-carousel-post-notification-item
                :border-color="
                  notification.team_color
                    ? `rgb(${notification.team_color})`
                    : '#7BE293'
                "
                :head="notification.title"
                :teamName="notification.team_name"
                :time="notification.datetime"
                :image="
                  notification.image_url
                    ? notification.image_url
                    : 'https://via.placeholder.com/140x50'
                "
                :customTagText="notification.status"
                :customTagColor="
                  notification.background_color
                    ? `rgb(${notification.background_color})`
                    : null
                "
                :customTagTextColor="
                  notification.text_color
                    ? `rgb(${notification.text_color})`
                    : null
                "
                :favorite="Boolean(notification.favorite_flg)"
                @click="toServicePage(notification)"
                :selected="checkSelectable(notification)"
                @select="good(notification)"
                :tag="judgeStatus(notification.status)"
                :tagText="notification.status"
                :isCursorPointer="
                  ['news', 'lecture', 'score'].includes(notification.category)
                "
              />
            </div>
          </div>
        </div>
      </template>
    </base-tab>
  </div>
</template>

<script>
import BaseTab from '@/components/base/BaseTab/BaseTab'
import AppCarouselPostNotificationItem from '@/components/app/AppCarousel/PostItem/AppCarouselPostNotificationItem'
import AppCarouselCommentNotificationItem from '@/components/app/AppCarousel/PostItem/AppCarouselCommentNotificationItem'
import AppCarouselCommentNotificationItemWide from '@/components/app/AppCarousel/PostItem/AppCarouselCommentNotificationItemWide.vue'
import { editTeamChatFavorite } from '@/helper/common.js'
import VueCookies from 'vue-cookies'

const cookies = VueCookies

export default {
  name: 'HomeNotice',
  components: {
    BaseTab,
    AppCarouselCommentNotificationItem,
    AppCarouselPostNotificationItem,
    AppCarouselCommentNotificationItemWide,
  },
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: '通知',
      headerColor: 'white',
      showSetting: false,
    })
    this.updateDisplayDate()
  },
  data() {
    return {
      current_tab: 'トーク',
      team_notifications: [],
      news_notifications: [],
      good_sending: false,
      talk_notifications: [],
      sso_notifications: [],
    }
  },
  created: async function () {
    this.$router.app.$emit('show-spinner', true)
    await this.initFetch()
    this.$router.app.$emit('show-spinner', false)
  },
  methods: {
    clickGood: async function (chat, chatId) {
      if (this.processing) return

      this.processing = true
      const targetFlg = !!chat.good_flg == true ? 0 : 1
      const { status } = await editTeamChatFavorite({
        chat_id: Number(chatId),
        good_flg: targetFlg,
      })
      if (status == 'success') {
        chat.good_flg = targetFlg
        if (targetFlg) chat.good_count++
        else chat.good_count--
      }
      this.processing = false
    },
    clickGoods: async function (chat, chatId) {
      if (this.processing) return

      this.processing = true
      const targetFlg = !!chat.good_flg == true ? 0 : 1
      const { status } = await editTeamChatFavorite({
        chat_id: Number(chatId),
        good_flg: targetFlg,
      })
      if (status == 'success') {
        chat.good_flg = targetFlg
        if (targetFlg) chat.goods_count++
        else chat.goods_count--
      }
      this.processing = false
    },
    comment() {
      if (this.chat.block_user_flg) {
        alert('ブロックしているのでコメントできません')
        return
      }

      this.$router.push(
        `/team/comment/${this.$route.params.team_id}/${this.$route.params.chat_id}/${this.chat.type}`
      )
    },
    tabChange(name) {
      this.$loginfo('Click tab:' + name)
      this.current_tab = name
    },
    fetchNotificationForTalk() {
      return this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/user/notification/talk',
          {
            params: {
              limit: 200,
            },
          }
        )
        .then((res) => {
          this.talk_notifications = res.data.value.notifications
        })
    },
    fetchSsoChatList() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/list', {
          params: {
            limit: '200',
            type: '4',
            display_type: 'all',
          },
        })
        .then((res) => {
          this.sso_notifications = res.data.value.chats
        })
    },
    fetchNotificationForTeam() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/notification', {
          params: {
            type: 'team',
            limit: 200,
          },
        })
        .then((res) => {
          this.team_notifications = res.data.value.notifications
        })
    },
    fetchNotificationForNews() {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/notification', {
          params: {
            type: 'news',
            limit: 200,
          },
        })
        .then((res) => {
          this.news_notifications = res.data.value.notifications
        })
    },
    async initFetch() {
      await Promise.all([
        this.fetchNotificationForTeam(),
        this.fetchNotificationForNews(),
        this.fetchNotificationForTalk(),
        this.fetchSsoChatList(),
      ])
    },
    judgeStatus(status) {
      if (!status) {
        return null
      }

      if (status === '受講してください') {
        return 'need'
      } else if (status === '受講完了') {
        return 'done'
      } else {
        return 'step'
      }
    },
    toTeamPage(notification) {
      // 確認済みフラグを立てる
      this.read(notification.notice_historys_id)

      if (notification.category === 'chat') {
        // カテゴリーがchatでchat_type判断する場合もあるようなので？
        if (notification.chat_type === 2) {
          this.$router.push(
            `/team/mini-vote-detail/${notification.team_id}/${notification.id}`
          )
        } else if (notification.chat_type === 3) {
          this.$router.push(
            `/team/post-detail/${notification.team_id}/${notification.id}`
          )
        } else {
          // 投稿閲覧画面
          this.$router.push(
            `/team/post-detail/${notification.team_id}/${notification.id}`
          )
        }
      } else if (notification.category === 'mini_vote') {
        // ミニ投票閲覧画面
        this.$router.push(
          `/team/mini-vote-detail/${notification.team_id}/${notification.id}`
        )
      } else if (notification.category === 'gift') {
        // メッセージ画面
        this.$router.push(
          `/team/post-detail/${notification.team_id}/${notification.id}`
        )
      } else if (notification.category === 'approval') {
        // メンバープロフィール
        this.$router.push(
          `/team/${notification.team_id}/member-profile/${notification.created_by}`
        )
      } else if (notification.category === 'member_add' || notification.category === 'approval_by_system') {
        // チームメンバー管理
        this.$router.push(`/team/${notification.team_id}/manage-member`)
      } else if (notification.category === 'team_join') {
        // チームホーム
        this.$router.push(`/team/info/${notification.team_id}`)
      } else if (notification.category === 'invited_team') {
        // あいことば画面
        this.$store.commit('invite/setToken', notification.token)
        this.$router.push(`/team/info/${notification.team_id}`)
      }
    },
    async toChatCommentWide(notification) {
      this.read(notification.notice_historys_id)

      await this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/info',
          {
            params: {
              comment_id: notification.comment_id,
            },
          }
        )
        .then((response) => {
          if (response.status != 200) {
            return
          }

          const index = response.data.value.index
          if (notification.category === 'chat_comment') {
            // 投稿閲覧画面
            this.$router.push(
              `/team/post-detail/${notification.team_id}/${notification.id}#comment-${notification.comment_id}-${index}`
            )
          } else if (notification.category === 'mini_vote_comment') {
            // ミニ投票閲覧画面
            this.$router.push(
              `/team/mini-vote-detail/${notification.team_id}/${notification.id}#comment-${notification.comment_id}-${index}`
            )
          }
        })
    },
    async toChatCommentReplyWide(notification) {
      this.read(notification.notice_historys_id)

      await this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/reply/info',
          {
            params: {
              comment_reply_id: notification.comment_reply_id,
            },
          }
        )
        .then((response) => {
          if (response.status != 200) {
            return
          }

          const index = response.data.value.index
          if (notification.chat_type === 1) {
            // 投稿閲覧画面
            this.$router.push(
              `/team/post-detail/${notification.team_id}/${notification.id}#reply-${notification.comment_reply_id}-${index}`
            )
          } else if (notification.chat_type === 2) {
            // ミニ投票閲覧画面
            this.$router.push(
              `/team/mini-vote-detail/${notification.team_id}/${notification.id}#reply-${notification.comment_reply_id}-${index}`
            )
          }
        })
    },
    async toChatComment(notification) {
      this.read(notification.notice_historys_id)
      // this.read(notification)
      // this.read(notification.comment_id)

      await this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/info',
          {
            params: {
              comment_id: notification.comment_id,
            },
          }
        )
        .then((response) => {
          if (response.status != 200) {
            return
          }

          const index = response.data.value.index
          if (notification.category === 'chat_comment') {
            // 投稿閲覧画面
            this.$router.push(
              `/team/post-detail/${notification.team_id}/${notification.id}#comment-${notification.comment_id}-${index}`
            )
          } else if (notification.category === 'mini_vote_comment') {
            // ミニ投票閲覧画面
            this.$router.push(
              `/team/mini-vote-detail/${notification.team_id}/${notification.id}#comment-${notification.comment_id}-${index}`
            )
          }
        })
    },
    toServicePage(notification) {
      this.read(notification.notice_historys_id)

      if (notification.category === 'news') {
        // サービスからのお知らせ
        this.$router.push(`/home/noticetext/${notification.id}`)
      } else if (notification.category === 'lecture') {
        // 講座閲覧
        this.$router.push(`/union/course/${notification.id}`)
      } else if (notification.category === 'score') {
        // スコアが更新されました
        this.$router.push(`/home/profile`)
      } else if (notification.category === 'new_device') {
        // 遷移なし
      }
    },
    async good(notification) {
      if (this.good_sending) {
        return
      }
      this.good_sending = true

      let good_flg = 0
      if (notification['good_flg']) {
        good_flg = 0
      } else {
        good_flg = 1
      }

      if (notification.category === 'lecture') {
        await this.$axios
          .post(
            '/api/' +
              this.$constants.API_VERSION +
              '/lecture_history/chat/favorite',
            {
              chat_users: [
                {
                  lecture_id: notification.id,
                  good_flg: good_flg,
                },
              ],
            }
          )
          .then(() => {
            this.news_notifications.forEach(
              function (v, i) {
                if (v.id === notification.id) {
                  if (v.category === 'lecture') {
                    this.news_notifications[i]['good_flg'] = good_flg
                  }
                }
              }.bind(this)
            )
          })
          .catch(() => {
            this.good_sending = false
          })
      } else if (['chat', 'mini_vote'].includes(notification.category)) {
        await this.chatFavorite(notification, good_flg)
      } else if (
        ['chat_comment', 'mini_vote_comment'].includes(notification.category)
      ) {
        await this.commentFavorite(notification, good_flg)
      }
      this.good_sending = false
    },
    checkSelectable(notification) {
      if (
        [
          'chat',
          'gift',
          'mini_vote',
          'chat_comment',
          'mini_vote_comment',
          'lecture',
        ].includes(notification.category)
      ) {
        return Boolean(notification.good_flg)
      } else {
        return null
      }
    },
    // スレッド単位のいいねフラグ更新
    chatFavorite: async function (notification, good_flg) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/favorite', {
          chat_users: [
            {
              chat_id: notification.id,
              good_flg: good_flg,
            },
          ],
        })
        .then(() => {
          this.team_notifications.forEach(
            function (v, i) {
              if (v.id === notification.id) {
                if (['chat', 'mini_vote'].includes(v.category)) {
                  this.team_notifications[i]['good_flg'] = good_flg
                }
              }
            }.bind(this)
          )
        })
        .catch(() => {
          this.good_sending = false
        })
    },
    // コメント単位のいいねフラグ更新
    commentFavorite: async function (notification) {
      const targetFlg = !!notification.good_flg == true ? 0 : 1
      this.$axios
        .post(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/user',
          {
            comment_id: notification.comment_id,
            good_flg: targetFlg,
          }
        )
        .then(() => {
          notification.good_flg = targetFlg
          if (notification.good_flg) notification.good_count++
          else notification.good_count--
          this.team_notifications.forEach(
            function (v, i) {
              if (
                v.id === notification.id &&
                v.comment_id === notification.comment_id
              ) {
                if (
                  ['chat_comment', 'mini_vote_comment'].includes(v.category)
                ) {
                  this.team_notifications[i]['good_flg'] = notification.good_flg
                }
              }
            }.bind(this)
          )
        })
        .catch(() => {
          this.good_sending = false
        })
    },
    // リプライ単位のいいねフラグ更新
    commentReplyFavorite: async function (notification) {
      const targetFlg = !!notification.good_flg == true ? 0 : 1
      this.$axios
        .post(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/reply/user',
          {
            comment_reply_id: notification.comment_reply_id,
            good_flg: targetFlg,
          }
        )
        .then(() => {
          notification.good_flg = targetFlg
          if (notification.good_flg) notification.good_count++
          else notification.good_count--
        })
        .catch(() => {
          this.good_sending = false
        })
    },
    read: function (notification_id) {
      this.$axios.post('/api/v1/user/notification/update', {
        id: notification_id,
        _method: 'PUT',
      })
    },
    goTo(featured_chat, type, id) {
      if (featured_chat.chat_id == '') {
        this.$router.push(`/union/course/${featured_chat.lecture_id}`)
      } else if (featured_chat.category == 'chat_comment' || featured_chat.category == 'mini_vote_comment') {
        // 投稿コメント・ミニ投票コメント
        this.toChatCommentWide(featured_chat)
      } else if (featured_chat.category == 'comment_reply') {
        this.toChatCommentReplyWide(featured_chat)
      } else {
        // 投稿・ミニ投票
        if (type == 2) {
          this.$router.push(
            `/team/mini-vote-detail/${featured_chat.team_id}/${id}`
          )
        } else {
          this.$router.push(`/team/post-detail/${featured_chat.team_id}/${id}`)
        }
      }
    },
    goToTeam(team_id) {
      this.$router.push(`/team/home/${team_id}`)
    },
    goToNews(news_id) {
      this.$router.push(`/home/noticetext/${news_id}`)
      // location.href = '/team/news/' + news_id
    },
    async goToSso(sso) {
      // IRET_JUNION_UNI_VERSE-2496 期限が切れたアクセストークンの更新を行うためログインチェックAPIを呼び出す
      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/auth/token/check', {}).then((response) => {
        if (response.status != 200) {
          return
        }
      }).catch((error) => {
        console.log(error)
        return
      })
      const auth_info = cookies.get('auth_info')
      if (auth_info.access_token) this.access_token = auth_info.access_token
      if (auth_info.refresh_token) this.refresh_token = auth_info.refresh_token

      // IRET_JUNION_UNI_VERSE-1858 ポップアップブロックONの対処のため、location.hrefで遷移
      let openWindow = window.open('', '_blank')
      // SSOリクエスト取得処理
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/auth/sso/request', {
          redirect_url: encodeURIComponent(sso.redirect_url),
          error_url: encodeURIComponent(
            `${process.env.VUE_APP_BASE_URL}/error/404`
          ),
          access_token: this.access_token,
          refresh_token: this.refresh_token,
          device: 'PC',
          tel_or_email: this.$store.state.auth.tel_or_email,
        })
        .then((response) => {
          if (response.status != 200) {
            return
          }
          // IRET_JUNION_UNI_VERSE-1858 ポップアップブロックONの対処のため、location.hrefで遷移
          openWindow.location.href = sso.sso_url + response.data.value.uuid
        })
        .catch((error) => {
          openWindow.close()
          this.$logerror(error)
          alert(error)
        })
    },
    updateDisplayDate() {
      this.$axios
      .put("/api/" + this.$constants.API_VERSION + "/user/notification/display")
    },
  },
}
</script>

<style lang="scss" module>
.home_notice {
  width: 100%;
  max-width: 1200px;
  padding-top: 60px;
  @include mobile() {
    padding-top: 32px;
  }
}

.team_tab_content,
.notice_tab_content {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 2px;
  margin: 0 auto;
  overflow: hidden;

  @include mobile() {
    width: auto;
    margin: 0;
    padding: 0 20px;
  }
}

// .team_item_list,
// .notice_item_list {
//   padding: 0;
//   margin: 0 0 0 0;
//   display: flex;
//   flex-wrap: wrap;

//   // 右端下端のマージンを打ち消す。ピッタリだと影が消えるので少し小さく取る
//   margin-right: -32px;
//   margin-bottom: -10px;
//   @include mobile() {
//     margin-right: -40px;
//     margin-bottom: -20px;
//   }

//   .team_item_wrap,
//   .notice_item_wrap {
//     margin-right: 20px;
//     margin-bottom: 20px;

//     @include mobile() {
//       margin-right: 24px;
//       margin-bottom: 24px;
//     }
//   }
// }
.team_item_list,
.notice_item_list {
  width: 100%;
  padding: 0;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0;
  @include mobile() {
    // margin-right: -40px;
    // margin-bottom: -20px;
  }

  .team_item_wrap,
  .notice_item_wrap {
    // margin-right: 20px;
    // margin-bottom: 20px;

    @include mobile() {
      // margin-right: 24px;
      // margin-bottom: 24px;
    }
  }
}
</style>
