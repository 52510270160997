<template>
  <section>
    <div :class="$style.post_detail">
      <div :class="$style.comment_form_wrap">
        <div :class="$style.page_title_line" v-if="true">
          <h3 :class="$style.comment_title">コメント</h3>
          <div :class="$style.cursor_pointer" @click="isShowArticle = !isShowArticle">
            <base-icon
              v-if="!isShowArticle"
              type="far"
              name="plus-square"
              size="20px"
              color="green"
            />
            <base-icon
              v-else-if="isShowArticle"
              type="far"
              name="minus-square"
              size="20px"
              color="green"
            />
            <a :class="$style.open_parent_article">親記事を展開する</a>
          </div>
        </div>
        <transition name="open">
          <div v-if="isShowArticle">
            <app-list-item-wide
        :key="chat.chat_id"
        :type="chat.type == 1 ? 'post' : 'vote'"
        :chat-id="chatId"
        :title="chat.title"
        :text="chat.formatted_text_web"
        :time="time(chat.start_datetime, chat.end_datetime, chat.created_at)"
        :teamId="Number($route.params.team_id)"
        :read="!!chat.read_flg"
        :clip="!!chat.pin_flg"
        :goodFlg="!!chat.good_flg"
        :profileUrl="chat.profile_url"
        :read-count="chat.read_count ? String(chat.read_count) : '0'"
        :comment-count="comment_count ? String(comment_count) : '0'"
        :goods-count="chat.good_count ? String(chat.good_count) : '0'"
        @select="clickGood"
        @read="clickRead"
        :showFavorite="false"
        :editChatFlg="false"
        :showPinFlg="false"
        :showShare="false"
        :showRead="false"
        :deleteAuthFlg="false"
        :body-image="chat.block_user_flg ? '' : chat.image_url ? chat.image_url : ''"
        :video-path="chat.video_path ? String(chat.video_path) : null"
        :display-video-path="chat.display_video_path ? String(chat.display_video_path) : null"
        :video-thumbnail-path="chat.video_thumbnail_path ? String(chat.video_thumbnail_path) : null"
        :youtube-id="chat.youtube_id ? String(chat.youtube_id) : null"
        :avatar-name="chat.chat_user_nickname"
        :avatar-image="
          chat.block_user_flg && !showPostView
            ? require('@/assets/images/block_chat_user.png')
            : chat.created_by_url
        "
        :path="chat.block_user_flg && !showPostView ? null : chat.path"
        :body-color="
          chat.block_user_flg && !showPostView
            ? '#DDDDDD'
            : chat.background_color
            ? `rgb(${chat.background_color})`
            : '#FFFFFF'
        "
        :body-file-list="
          chat.block_user_flg && !showPostView ? null : chat.path_list
        "
        :showPost="chat.block_user_flg && !showPostView ? false : true"
        :blockUser="chat.block_user_flg"
        :switchPost="chat.block_user_flg && !showPostView ? true : false"
        :report_flg="chat.is_user_reported"
        :showReport="
          $store.state.auth.user_id == chat.created_by ? false : true
        "
        @report="reportUser(chat)"
        :showEllipsis="
          $store.state.auth.user_id == chat.created_by ? false : true
        "
        :spam_flg="chat.spam_flg"
        @blockUser="blockUser(chat)"
        @switchPostView="switchPostView"
        :open_flg="chat.open_flg"
        :start_datetime="chat.start_datetime"
        :end_datetime="chat.end_datetime"
        @spam="spamChat(chat)"
        :comment-notice-flg="!!chat.comment_notice_flg"
        :mention-notice-flg="!!chat.mention_notice_flg"
      ></app-list-item-wide>
          </div>
      </transition>

        <div :class="$style.comment_form" v-if="true">
          <label :class="$style.comment_form_label" :for="'body'">コメント本文</label>
          <div v-if="!comment_id">
            <base-select-taggable
              name="to_personal"
              :options="to_personal_options"
              v-model="to_personal_selected"
              multiple
              :loading="to_personal_loading"
              placeholder="コメントをお知らせしたいメンバー"
              :error="isFieldErrorMsg('to_personal', errorMsgList)"
              :is_exist_choicesLabel="true"
              :close-on-select="false"
            >
            </base-select-taggable>
          </div>
            <base-input-comment
              name="comment"
              :showStampIcon="true"
              stampIconName="stamp_03.svg"
              :showRecordIcon="false"
              :showFileIcon="false"
              @update="setComment"
              @text_changed="onChangeText"
              @text_length="getTextLength"
              @stamp="postStamp"
              :textareaValue="comment ? comment : ''"
              :commentErrorList="errorMsgList"
              :rows="10"
            />
            <div :class="$style.title_text">{{text_length}}/5000</div>
            <div v-if="!comment_id">
              <div :class="$style.member_avatar_list">
                <div v-for="user in to_personal_selected" :key="user.user_id">
                  <div :class="$style.member_avatar_icon">
                      <base-avatar size="60px" :path="user.profile_image_path ? user.profile_image_path : NoImage" @click.native="showProfile(user)"/>
                  </div>
                </div>
              </div>
              <base-modal
                  v-if="showProfileModal"
                  type="round"
                  @close="showProfileModal = false"
                >
                <template v-slot:body>
                  <div :class="$style.modal_content">
                    <div :class="$style.member_avatar" >
                      <base-avatar :class="$style.member_avatar_image" size="120px" :path="dialogUser.profile_image_path ? dialogUser.profile_image_path : NoImage" />
                    </div>
                    <div>
                      <h1 :class="$style.member_nickname">{{ dialogUser.nickname }}</h1>
                      <div :class="$style.member_meta_wrap">
                        <dl :class="$style.member_meta">
                          <dt>所属:</dt>
                          <dd>{{ dialogTeamListValue.affiliation }}</dd>
                          <dt>ju-ID:</dt>
                          <dd>{{ dialogUser.juid }}</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </template>
              </base-modal>
            </div>
            <CommentTextField
              name="attached_file"
              :class="$style.cursor_pointer"
              :showStampIcon="false"
              :showRecordIcon="false"
              :showTextArea="false"
              fileIconName="upload_file.svg"
              @file_updated="onFileSubmit"
              @file_delete="onFileDelete"
              @error="onFileError"
              textareaValue="添付ファイルを追加"
              :maxFileSize="50"
              :files="files"
              :imageTypes="imageTypes"
              v-if="!comment_id"
            />
          <div v-if="comment_id">※編集時は添付ファイルを追加できません。</div>
        </div>
      </div>
      <div :class="$style.post_detail_footer">
        <p v-if="!chat.comment_view_type" :class="$style.comment_private_notes">※本投稿へのコメントは組織管理者のみ公開されます。</p>
        <div :class="$style.confirm_button_wrap">
          <base-button
            bordered
            rounded="rounded"
            width="120px"
            @click="cancelComment"
            >キャンセル</base-button
          >
          <div v-if="comment_id">
            <base-button rounded="rounded" width="120px" @click="editComment"
              >編集</base-button
            >
          </div>
          <div v-else>
            <base-button rounded="rounded" width="120px" @click="sendPost"
              >送信</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import CommentListItem from '@/pages/team/CommentListItem'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import CommentTextField from '@/pages/team/CommentTextField'
import BaseInputComment from "@/components/base/BaseInputComment/BaseInputComment";
import { hasError, isFieldErrorMsg } from '@/helper/validator.js'
import { editTeamChatFavorite } from '@/helper/common.js'
import { getBase64FromDataUrl } from '../../utils/helper'
import 'floating-vue/dist/style.css'
import AppListItemWide from '@/components/app/AppListItemWide/AppListItemWide'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseSelectTaggable from "@/components/base/BaseSelectTaggable/BaseSelectTaggable";
import BaseAvatar from "@/components/base/BaseAvatar/BaseAvatar";
import NoImage from '@/assets/images/account-circle.svg';
import BaseModal from '@/components/base/BaseModal/BaseModal'

export default {
  name: 'TeamPostDetail',
  components: { BaseButton, CommentTextField, BaseInputComment, AppListItemWide, BaseIcon, BaseSelectTaggable, BaseAvatar, BaseModal},
  mounted() {
    this.$router.app.$emit('page-updated', {
      pageTitle: 'コメント',
      showSetting: false,
      back: `/team/post-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}`,
      headerColor: 'yellow',
      bgColor: 'white',
      showPcBackBtn: true,
      comment_title: ""
    })
  },
  props: {
    headerMenuValue: String,
  },
  watch: {
    headerMenuValue(newVal) {
      if (newVal == 'spam') {
        this.spamChat(this.chat)
      }
    }
  },
  data() {
    return {
      showPostView: true,
      showCommentForm: false,
      showFileIcon: true,
      showStampIcon: true,
      chat: {},
      comment_count: '',
      comment_id: '',
      comment: '',
      plain_text: '',
      file_list: [],
      stamp_id: '',
      errorMsgList: [],
      processing: false,
      files: [],
      imageTypes: [
        'image', // 登録済みの画像形式
        'image/apng', // APNG
        'image/avif', // AVIF
        'image/gif', // GIF
        'image/jpeg', // JPEG
        'image/png', // PNG
        'image/svg+xml', // SVG
        'image/webp', // WebP
      ],
      users: [],
      users_count: 0,
      mentions: [],
      mentionTo: '',
      isShowArticle: false,
      text_length: 0,
      to_personal_options: [],
      to_personal_selected: [],
      to_personal_loading: true,
      NoImage,
      showProfileModal: false,
      dialogUser: null,
      dialogTeamListValue: {},
    }
  },
  computed: {},
  methods: {
    hasError,
    isFieldErrorMsg,
    getTextLength: function (value) {
      this.text_length = value
    },
    fetchUserInfo: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((res) => {
          if (res.data.status == 'success') {
            this.user_id = res.data?.value?.user?.user_id
            this.nickname = res.data?.value?.user?.nickname
            this.juid = res.data?.value?.user?.juid
          } else {
            this.user_id = null
            this.nickname = null
            this.juid = null
          }
        })
        .catch((err) => {
          this.user_id = null
          this.nickname = null
          this.juid = null
          throw new Error(err)
        })
    },
    fetchChatInfo: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/info', {
          params: { chat_id: this.$route.params.chat_id },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.chat = res.data.value
            this.$emit('chat', this.chat)
            const back_page = this.chat.type === 2 ? 'mini-vote-detail' : 'post-detail'

            // ヘッダーにタイトルを表示
            this.$router.app.$emit('page-updated', {
              pageTitle: 'コメント',
              showSetting: false,
              back: `/team/${back_page}/${this.$route.params.team_id}/${this.$route.params.chat_id}`,
              headerColor: 'yellow',
              bgColor: 'white',
              showPcBackBtn: true,
              comment_title: this.chat.title
            })

            if (this.chat.block_user_flg) {
              this.showPostView = false
            } else {
              this.showPostView = true
            }
          } else {
            this.chat = null
          }
        })
        .catch((err) => {
          this.chat = null
          throw new Error(err)
        })
    },
    fetchCommentList: function () {
      return this.$axios
        .get(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/list',
          {
            params: {
              chat_id: this.$route.params.chat_id,
              sort: 'created_at',
              direction: 'asc',
            },
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.comments = res.data.value.comments
            this.comment_count = res.data.value.comment_count
          } else {
            this.comments = res.data.value.comments
            this.comment_count = res.data.value.comment_count
          }
        })
        .catch((err) => {
          this.$loginfo(err)
          this.chat = null
          throw new Error(err)
        })
    },
    fetchUserList: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/list', {
          params: {
            team_id: this.$route.params.team_id,
            user_team_status: "approved,no_approval_required",
          },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            const users = []
            const data = res.data.value
            for(let i = 0; i < data.length; i++){
              let obj = {}
              obj.value = data[i].nickname
              obj.user_id = data[i].user_id
              users.push(obj)
            }
            this.users = users;
          } else {
            console.log(res.data)
          }
        })
        .catch((err) => {
          this.chat = null
          throw new Error(err)
        })
    },
    fetchMentionUserList: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/mention/list', {
          params: { chat_id: this.$route.params.chat_id },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            const userList = res.data.value?.mention_user_list
            if (userList) {
              userList.forEach((user) => {
                this.to_personal_options.push({
                  id: user.user_id,
                  juid: user.juid,
                  nickname: user.nickname,
                  label: user.nickname
                    ? user.nickname
                    : user.name
                    ? user.name
                    : user.juid,
                  choicesLabel: user.nickname_juid,
                  profile_image_path: user.profile_image_path
                });
              });
            }
            this.to_personal_loading = false;
            console.log(res.data.value?.mention_user_list)
            this.users = res.data.value.mention_user_list.map(user => {
              return {
                user_id: user.user_id,
                value: user.nickname,
              }
            })
            this.users_count = this.users.length;
          } else {
            console.log(res.data)
          }
        })
        .catch((err) => {
          this.chat = null
          throw new Error(err)
        })
    },
    setComment: function (value) {
      this.errorMsgList = []
      this.comment = value
    },
    onChangeText: function (text) {
      this.plain_text = text;
    },
    setMentions: function () {
      this.mentions = []

      if (this.to_personal_selected.length == 0) {
        // メンション対象が選択されていない場合はreturn
        return true
      }

      // メンション対象ユーザーのuser_idの配列を作成する
      this.mentions = this.to_personal_selected.map(function(selected){
        return { user_id: selected.id }
      })

      return true
    },
    onFileSubmit([data_url, file]) {
      this.errorMsgList = []
      if (1 <= this.files.length) {
        this.errorMsgList.push({
          attribute: 'path_list',
          message: '添付ファイルは1件まで登録できます。',
        })
        return
      }

      this.files.push({
        id: '',
        type: file.type,
        name: file.name,
        url: data_url,
        is_new: true,
      })
    },
    onFileDelete(file, index) {
      file
      index
      this.errorMsgList = []
      this.files = []
    },
    onFileError(errors) {
      this.errorMsgList = []
      if (errors.filesize) {
        this.errorMsgList.push({
          attribute: 'path_list',
          message: '添付ファイルのファイルサイズの上限は50Mbyteです。',
        })
      }
    },
    sendPost() {
      if (!this.setMentions()) return
      if (this.files.length == 0) {
        this.postComment(true, true)
      } else {
        this.postFile()
      }
    },
    postFile: function () {
      if (this.processing) return
      this.processing = true
      this.$router.app.$emit('show-spinner', true)
      this.heavyProcess()
    },
    heavyProcess() {
      this.errorMsgList = []
      let extension = this.files[0].name.substring(
        this.files[0].name.lastIndexOf('.')
      )
      let file_type = ''
      let comment_type = null

      if (
        extension.toUpperCase().match(/\.(jpg)$/i) ||
        extension.toUpperCase().match(/\.(jpeg)$/i) ||
        extension.toUpperCase().match(/\.(png)$/i) ||
        extension.toUpperCase().match(/\.(gif)$/i)
      ) {
        file_type = 'image'
        comment_type = 2
      } else if (extension.toUpperCase().match(/\.(aac)$/i)) {
        file_type = 'audio_file'
        comment_type = 4
      } else {
        file_type = 'file'
        comment_type = 2
      }
      var convert_base64 = getBase64FromDataUrl(this.files[0].url)
      this.file_list.push({
        file_name: this.files[0].name,
        file: convert_base64,
        file_type: file_type,
      })

      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/registration',
          {
            chat_id: this.$route.params.chat_id,
            type: comment_type,
            file_list: this.file_list,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            if (this.comment.length == 0) {
              if (
                this.$route.params.type == 1 ||
                this.$route.params.type == 4
              ) {
                this.$router.push(
                  `/team/post-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}/comment`
                )
              } else if (this.$route.params.type == 2) {
                this.$router.push(
                  `/team/mini-vote-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}/comment`
                )
              }
            } else {
              this.postComment(true)
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          this.$router.app.$emit('show-spinner', false)
        })
    },
    postStamp: function (args) {
      this.errorMsgList = []
      this.stamp_id = args.id
      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/registration',
          {
            chat_id: this.$route.params.chat_id,
            type: 3,
            stamp_id: this.stamp_id,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            if (this.$route.params.type == 1 || this.$route.params.type == 4) {
              this.$router.push(
                `/team/post-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}/comment`
              )
            } else if (this.$route.params.type == 2) {
              this.$router.push(
                `/team/mini-vote-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}/comment`
              )
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          this.$router.app.$emit('show-spinner', false)
        })
    },
    editComment: function () {
      this.errorMsgList = []
      this.$axios
        .put(
          '/api/' + this.$constants.API_VERSION + '/team/chat/comment/update',
          {
            comment_id: this.$route.params.comment_id,
            type: 1,
            formatted_text_web: this.comment,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            if (this.$route.params.type == 1 || this.$route.params.type == 4) {
              this.$router.push(
                `/team/post-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}`
              )
            } else if (this.$route.params.type == 2) {
              this.$router.push(
                `/team/mini-vote-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}`
              )
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
        })
    },

    /**
     * メンション対象ユーザーに選択されているユーザーのニックネームを
     * mentionタグで囲んだ文字列を取得する
     */
    getSelectedUserWrapInMentionTag: function () {

      if (this.to_personal_selected.length == 0){
        return ''
      }

      let wrapped_list = this.to_personal_selected.map(function(selected){
        return `<mention juid="${selected.juid}">@${selected.nickname}</mention>`
      })

      let result = wrapped_list.join(' ')
      result = '<p>' + result + '</p>'
      return result
    },

    postComment: function (is_redirect = true, toggle_processing = false) {
      // スクロールアップする処理
      window.scrollTo(0, 0);

      this.$router.app.$emit('show-spinner', true)
      if (toggle_processing) {
        if (this.processing) return
        this.processing = true
      }

      this.errorMsgList = []
      let formatted_text_web = this.comment

      // メンション対象ユーザーにmentionタグを付ける
      let mention_string = this.getSelectedUserWrapInMentionTag()

      formatted_text_web = mention_string + formatted_text_web

      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/registration',
          {
            chat_id: this.$route.params.chat_id,
            type: 1,
            text: this.plain_text,
            formatted_text_web: formatted_text_web,
            mentions: JSON.parse(JSON.stringify(this.mentions)),
          }
        )
        .then((res) => {

          if (res.data.status == 'success' && is_redirect) {
            this.to_personal_selected = []
            if (this.$route.params.type == 1 || this.$route.params.type == 4) {
              this.$router.push(
                `/team/post-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}/comment`
              )
            } else if (this.$route.params.type == 2) {
              this.$router.push(
                `/team/mini-vote-detail/${this.$route.params.team_id}/${this.$route.params.chat_id}/comment`
              )
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          this.$router.app.$emit('show-spinner', false)
        })
    },
    cancelComment: function () {
      if (this.$route.params.type == 1 || this.$route.params.type == 4) {
        this.$router.push({
          name: 'TeamPostDetail',
          params: {
            team_id: this.$route.params.team_id,
            chat_id: this.$route.params.chat_id,
          },
        })
      }
      if (this.$route.params.type == 2) {
        this.$router.push({
          name: 'TeamMiniVoteDetail',
          params: {
            team_id: this.$route.params.team_id,
            chat_id: this.$route.params.chat_id,
          },
        })
      }
    },
    async initFetch() {
      this.initLoading = true
      try {
        await Promise.all([this.fetchChatInfo(), this.fetchCommentList(), this.fetchMentionUserList()])
      } catch (e) {
        // this.$router.push('/error')
        this.$loginfo(e)
      } finally {
        this.initLoading = false
        setTimeout(function () {
          scrollTo(0, 20000)
        }, 200)
      }
    },
    clickGood: async function () {
      if (this.processing) return

      this.processing = true

      const good_flg = !!this.chat.good_flg
      let param_good_flg = 1
      if (good_flg) {
        param_good_flg = 0
      }

      const result = await editTeamChatFavorite({
        chat_id: this.$route.params.chat_id,
        good_flg: param_good_flg,
      })
      if (result.status == 'success') {
        if (good_flg) {
          this.chat.good_count--
        } else {
          this.chat.good_count++
        }
        this.chat.good_flg = param_good_flg
      }

      this.processing = false
    },
    clickRead: async function () {
      if (this.processing) return

      this.processing = true
      // 投稿内ユーザー登録・更新 [既読可否]
      const targetFlg = !!this.chat.read_flg == true ? 0 : 1
      const { status } = await editTeamChatFavorite({
        chat_id: this.$route.params.chat_id,
        read_flg: targetFlg,
      })
      if (status == 'success') {
        this.chat.read_flg = targetFlg
        if (targetFlg) this.chat.read_count++
        else this.chat.read_count--
      }
      this.processing = false
    },
    blockUser: async function (chat) {
      if (this.processing) {
        return
      }
      this.processing = true
      if (chat.block_user_flg) {
        if (!confirm('ユーザのブロックを解除しますか？')) {
          this.request_sending = false
          return
        }
        await this.deleteBlockUser(chat.created_by)
      } else {
        if (!confirm('ユーザをブロックしますか？')) {
          this.request_sending = false
          return
        }
        await this.updateBlockUser(chat.created_by)
      }
      this.processing = false
    },
    async updateBlockUser(block_user_id) {
      await this.$axios
        .post(
          '/api/' + this.$constants.API_VERSION + '/user/block/registration',
          {
            block_user_id: block_user_id,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.fetchChatInfo()
            alert('ユーザをブロックしました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async deleteBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/delete', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.fetchChatInfo()
            alert('ユーザのブロックを解除しました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    showProfile: async function(user){
      console.log('showProfile')
      console.log(user)
      this.dialogTeamListValue = await this.fetchTeamList(user.id)
      this.showProfileModal = true
      this.dialogUser = user
    },
    fetchTeamList: async function(user_id){
      let team_list_value

      let params = {
          "user_id": user_id,
          "search_type": "login",
          "sort": "name",
          "direction": "asc",
          "limit": 1000
      }

      if(this.$store.state.auth.user_id !== this.user_id){
          // IRET_JUNION_UNI_VERSE-1882
          // 他のユーザーからプロフィールを表示した場合は検索対象としないチームは表示対象外
          params.open_flg = 1;
        }

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/list', { params }).then((res) => {
        team_list_value = res.data.value
      })

      return team_list_value
    },
    switchPostView() {
      if (this.processing) {
        return
      }
      this.showPostView = !this.showPostView
    },
    reportUser: async function (chat) {
      if (this.processing) {
        return
      }
      if (chat.is_user_reported) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このユーザを悪質なユーザとして報告しますか？')) {
          return
        }
        this.processing = true
        await this.callReportUser(chat.created_by)
      }
      this.processing = false
      return
    },
    callReportUser: async function (user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/report', {
          reported_user_id: user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    spamChat: async function (chat) {
      if (this.processing) {
        return
      }
      if (chat.spam_flg) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('この投稿を悪質な投稿として報告しますか？')) {
          return
        }
        this.processing = true
        await this.reportSpamChat(this.$route.params.chat_id) //レスポンスにない為
      }
      this.processing = false
    },
    async reportSpamChat(chat_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/spam', {
          chat_id: chat_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    time: function (start_datetime, end_datetime, created_at) {
      let startFormatStr = ''
      let endFormatStr = ''

      if (start_datetime) {
        startFormatStr = this.$dayjs(start_datetime).format('YYYY/MM/DD HH:mm')
      } else if (created_at) {
        startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
      }
      if (end_datetime) {
        endFormatStr = this.$dayjs(end_datetime).format('YYYY/MM/DD HH:mm')
      }
      // 表示設定
      if (startFormatStr && !endFormatStr) {
        return startFormatStr
      }
      if (startFormatStr && endFormatStr) {
        return startFormatStr + ' - ' + endFormatStr
      }
      return ''
    },
  },
  async created() {
    this.chatId = this.$route.params.chat_id
    await this.initFetch()
    this.comment = ''
    if (this.$route.params.comment_id) {
      this.comment_id = this.$route.params.comment_id
      this.showFileIcon = false
      this.showStampIcon = false

      this.comments.forEach((elem) => {
        if (elem.comment_id == this.comment_id) {
          this.comment = elem.formatted_text_web
        }
      })
    }
  },
}
</script>
<style lang="scss" module>
.post_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.post_comments {
  width: 100%;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}
.post_comments_item {
  padding: 16px 32px;
}

.comment_form_wrap {
  width: 100%;
  max-width: 1280px;
  padding-top: 2em;
  display: block;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .comment_form {
    margin: 14px 0 18px 12px;

    &_label {
      display: block;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.post_detail_action {
  width: 100%;
  padding: 25px 0 50px;
  text-align: center;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}

.post_detail_footer {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  background: $keyYellow;
  z-index: 20;
  padding: 10px;
  width: 100%;

  .comment_private_notes {
    text-align: center;
    color: red;
  }

  @include mobile() {
    margin: 24px 0;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    width: 100%;
    height: 85px;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}
.title_text {
  padding: 0 2px;
  text-align: right;
  font-size: small;
  color: #b7b7b7;
}
.page_title_line {
  width: 100%;
  max-width: 1280px;
  margin-right: 5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .open_parent_article {
    border-bottom: #000000 1px solid;
    margin-left: 0.5em;
  }
}
@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.open-enter-active {
  animation: open 2s;
}
.open-leave-active {
  animation: open 2s linear reverse;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: #000;
  color: #fff;
  border-radius: 16px;
  padding: 5px 10px 4px;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #000;
  z-index: 1;
}
.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}
.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}
.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^='right'] {
  margin-left: 5px;
}
.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^='left'] {
  margin-right: 5px;
}
.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: #000;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}
.mention-selected {
  background: rgb(192, 250, 153);
}

.cursor_pointer {
  cursor: pointer;
}

.member_nickname {
  font-size: 30px;
  color: $fontBlack;
  margin: 30px 0 4px;
  text-align: center;
  @include mobile(){
    margin: 20px 0 4px;
    font-size: 20px;
  }
}

.member_meta_wrap {
}
.member_meta {
  font-size: 13px;
  color: $fontGray;
  line-height: 24px;
  margin: 30px 32px 42px;
  @include mobile(){
    font-size: 10px;
    margin: 24px 32px;
  }

  dt {
    width: 80px;
    float: left;
  }
  dd {
    margin: 0;
    overflow: hidden;
  }
}

.modal_content {
  margin: 20px;
  width: auto;
  // display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {
    width: auto;
    margin: 20px;
  }
}

.member_avatar_image {
  margin: 0 auto;
}

.member_avatar_list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-left: 40px;
}

.member_avatar_icon {
  cursor: pointer;
  text-align: left;
  margin: 5px;
}
</style>
