<template>
  <form class="$style.form" @submit.prevent="update()">
    <div :class="$style.bg1">
      <div :class="$style.bg2">
        <app-prev-button margin="25px 10px 0px" @click="backButtonAction(prevRoute)" />
        <div :class="$style.contents">
          <base-form-field label="チーム名" name="name">
          <a :class="$style.required">(必須)</a>
            <span class="validate-error" v-if="v$.inputName.$invalid && v$.inputName.$dirty"></span>
            <base-input
              name="name"
              id="name"
              v-model="inputName"
              @focus="onFocus"
              @blur="onBlur"
              :error="v$.inputName.$invalid && v$.inputName.$dirty ? v$.inputName.$errors[0].$message : isFieldErrorMsg('name', errorMsgList)"
            />
          </base-form-field>

          <base-form-field name="approval_flg" label="招待形式">
          <a :class="$style.required">(必須)</a>
            <span class="validate-error" v-if="v$.inputApprovalFlg.$invalid && v$.inputApprovalFlg.$dirty"></span>
            <base-select
              :class="$style.to_select"
              name="approval_flg"
              id="approval_flg"
              v-model="inputApprovalFlg"
              :options="approvalFlgOptions"
              placeholder="選択してください"
              @focus="onFocus"
              @blur="onBlur"
              :error="v$.inputApprovalFlg.$invalid && v$.inputApprovalFlg.$dirty ? v$.inputApprovalFlg.$errors[0].$message : isFieldErrorMsg('approval_flg', errorMsgList)"
            />
          </base-form-field>

          <span class="validate-error" v-if="isFieldErrorMsg('file_cover_name', errorMsgList)"></span>
          <base-form-field :class="$style.file_wrap" name="cover_image" label="カバー画像">
          <a :class="$style.required">(必須)</a>
            <base-file-input
              :class="$style.file_input"
              name="cover_image"
              id="cover_image"
              @change="setCoverImageFile"
              @focus="onFocus"
              @blur="onBlur"
            >画像を変更</base-file-input>
            <p></p>
            <img v-if="gotDefaultImage"
              :class="[$style.cover_image, defaultCoverImage ? $style.default_height : '']"
              :src="inputCoverImage || defaultCoverImage || 'https://via.placeholder.com/400x250'"
              alt="">
            <p v-if="isFieldErrorMsg('file_cover_name', errorMsgList)" :class="$style.error_text">{{ isFieldErrorMsg('file_cover_name', errorMsgList) }}</p>
          </base-form-field>

          <span class="validate-error" v-if="isFieldErrorMsg('file_badge_name', errorMsgList)"></span>
          <base-form-field :class="$style.file_wrap" name="badge_image" label="バッジ画像">
          <a :class="$style.required">(必須)</a>
            <base-file-input
              :class="$style.file_input"
              name="badge_image"
              id="badge_image"
              @change="setBadgeImageFile"
              @focus="onFocus"
              @blur="onBlur"
            >画像を変更</base-file-input>
            <base-avatar v-if="gotDefaultImage"
              size="60px"
              :path="inputBadgeImage || defaultBadgeImage || 'https://via.placeholder.com/200x200'"
              bordered />
            <p v-if="isFieldErrorMsg('file_badge_name', errorMsgList)" :class="$style.error_text">{{ isFieldErrorMsg('file_badge_name', errorMsgList) }}</p>
          </base-form-field>

          <base-form-field name="team_color" label="チームテーマ">
          <a :class="$style.required">(必須)</a>
            <span class="validate-error"
            v-if="(v$.inputTeamColor.$invalid && v$.inputTeamColor.$dirty)
            || isFieldErrorMsg('team_color', errorMsgList)"></span>
            <div :class="$style.team_color">
              <div v-if="inputTeamColor" :style="{ background: 'rgb(' + inputTeamColor + ')' }" :class="$style.team_color_thumbnail" />
              <base-select
                :class="[$style.team_color_select, { [$style.team_color_select_selected_color]: !!inputTeamColor }]"
                name="team_color"
                id="team_color"
                v-model="inputTeamColor"
                @focus="onFocus"
                @blur="onBlur"
                :options="teamColorOptions"
                placeholder="選択してください"
                :error="v$.inputTeamColor.$invalid && v$.inputTeamColor.$dirty ? v$.inputTeamColor.$errors[0].$message : isFieldErrorMsg('team_color', errorMsgList)"
              />
            </div>
          </base-form-field>

          <base-form-field label="プロフィール" name="profile">
          <a :class="$style.required">(必須)</a>
            <span class="validate-error"
            v-if="(v$.inputProfile.$invalid && v$.inputProfile.$dirty)
            || isFieldErrorMsg('profile', errorMsgList)"></span>
            <base-text-area
              name="profile"
              id="profile"
              v-model="inputProfile"
              @focus="onFocus"
              @blur="onBlur"
              :rows="4"
              :error="v$.inputProfile.$invalid && v$.inputProfile.$dirty ? v$.inputProfile.$errors[0].$message : isFieldErrorMsg('profile', errorMsgList)"
            />
          </base-form-field>

          <base-form-field label="URL" name="url">
            <span class="validate-error"
            v-if="(v$.inputUrl.$invalid && v$.inputUrl.$dirty)
            || isFieldErrorMsg('url_type', errorMsgList)
            || isFieldErrorMsg('url', errorMsgList)"></span>
            <base-input
              name="url"
              id="url"
              v-model="inputUrl"
              @focus="onFocus"
              @blur="onBlur"
              :error="v$.inputUrl.$invalid && v$.inputUrl.$dirty ? v$.inputUrl.$errors[0].$message : isFieldErrorMsg('url', errorMsgList)"
            />
            <div :class="$style.check_box_wrap">
              <base-check-box
                value="1"
                name="url_type"
                :class="$style.check_box"
                v-model="inputUrlTypes"
              >チームプロフィールに表示</base-check-box>
              <base-check-box
                v-if="publicStatus === 'public_approved'"
                value="2"
                name="url_type"
                :class="$style.check_box"
                v-model="inputUrlTypes"
                @focus="onFocus"
                @blur="onBlur"
                :error="isFieldErrorMsg('url_type', errorMsgList)"
              >ユーザーHOMEに追加</base-check-box>
            </div>
          </base-form-field>

          <base-form-field label="チームメンバーができること" name="user" expand expanded>
            <base-check-box
              value="1"
              name="member_comment_add_flg"
              :class="$style.check_box"
              v-model="inputMemberCommentAddFlg"
            >コメント</base-check-box>
            <base-check-box
              value="1"
              name="member_chat_add_flg"
              :class="$style.check_box"
              v-model="inputMemberChatAddFlg"
            >新規作成（投稿）</base-check-box>
            <base-check-box
              value="1"
              name="member_mini_vote_add_flg"
              :class="$style.check_box"
              v-model="inputMemberMiniVoteAddFlg"
            >新規作成（ミニ投票）</base-check-box>
            <base-check-box
              value="1"
              name="member_invite_flg"
              :class="$style.check_box"
              v-model="inputMemberInviteFlg"
            >メンバー招待</base-check-box>
            <base-check-box
              value="1"
              name="member_list_view_type"
              :class="$style.check_box"
              v-model="inputMenmberViewFlg"
            >メンバー一覧の閲覧</base-check-box>
            <p :class="$style.description_indent">※ メンバー一覧の閲覧が見れない状態では、宛先の個人指定ができません。</p>
          </base-form-field>

          <base-form-field label="共同管理者" name="admin_auth" v-if="this.authType != 'admin_auth'" expand expanded>
            <base-check-box
              value="1"
              name="joint_admin_invite_flg"
              :class="$style.check_box"
              v-model="inputJointAdminInviteFlg"
            >メンバー招待/承認</base-check-box>
          </base-form-field>

          <base-form-field label="あいことば" name="common_word">
          <a :class="$style.required">(必須)</a>
            <span class="validate-error"
            v-if="(v$.inputCommonWord.$invalid && v$.inputCommonWord.$dirty)
            || isFieldErrorMsg('common_word', errorMsgList)"></span>
            <p :class="$style.description">招待の際にあいことばを問います</p>
            <base-input
              name="common_word"
              id="common_word"
              v-model="inputCommonWord"
              @focus="onFocus"
              @blur="onBlur"
              :error="v$.inputCommonWord.$invalid && v$.inputCommonWord.$dirty ? v$.inputCommonWord.$errors[0].$message : isFieldErrorMsg('common_word', errorMsgList)"
            />
          </base-form-field>

          <base-form-field label="チームの公開設定" name="open_flg">
            <base-check-box
              value="1"
              name="open_flg"
              :class="$style.check_box"
              v-model="inputOpenFlg"
            >検索対象とする</base-check-box>
          </base-form-field>

          <base-form-field name="" label="オフィシャルチーム" v-if="!teamCode && !token">
            <p :class="[$style.description, $style.description_small]">
              j.unionから承認を得るとオフィシャルチームになります。<br />
              <a @click="startMailer" :class="$style.cursor_pointer">j.unionへ依頼する</a>
            </p>
          </base-form-field>

          <template v-if="hasError(errorMsgList) && !hasFieldErrorMsg(errorMsgList, [])">
            <base-message
            type="error" v-for="(error, i) in errorMsgList" :key="i"
            :class="$style.msg_area">{{error.message}}</base-message>
          </template>

          <!-- <base-form-action accept-label="設定完了" /> -->
        </div>
        <div :class="$style.edit_footer">
          <div :class="$style.confirm_button_wrap">
            <base-button
              bordered
              rounded="rounded"
              width="120px"
              @click="cancel"
              :disabled="update_sending"
            >キャンセル</base-button>
            <base-button
              rounded="rounded"
              width="120px"
              @click="update_sending=true,update"
              :disabled="update_sending"
            >設定完了</base-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import BaseAvatar from "@/components/base/BaseAvatar/BaseAvatar";
import BaseCheckBox from "@/components/base/BaseCheckBox/BaseCheckBox";
import BaseFileInput from "@/components/base/BaseFileInput/BaseFileInput";
import BaseFormField from "@/components/base/BaseFormField/BaseFormField";
import BaseInput from "@/components/base/BaseInput/BaseInput";
import BaseTextArea from "@/components/base/BaseTextArea/BaseTextArea";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect";
import BaseMessage from '@/components/base/BaseMessage/BaseMessage'
import useVuelidate from '@vuelidate/core'
import { required, maxLength, url, helpers } from '@vuelidate/validators'
// import BaseFormAction from "@/components/base/BaseFormAction/BaseFormAction";
import BaseButton from "@/components/base/BaseButton/BaseButton";
import {isFieldErrorMsg, hasError, hasFieldErrorMsg} from '@/helper/validator.js'
import AppPrevButton from '@/components/app/AppPrevButton/AppPrevButton'

export default {
  name: 'TeamCreate',
  components: {
    BaseAvatar,
    BaseCheckBox,
    BaseFileInput,
    BaseSelect,
    BaseFormField,
    BaseInput,
    BaseTextArea,
    BaseButton,
    BaseMessage,
    AppPrevButton
  },
  props: {
    team_id: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.getDefaultImage()
    this.$router.app.$emit('page-updated', {
      pageTitle: this.team_id ? 'チーム設定' : '新しいチームを作成',
      showSetting: false,
      back: this.prevRoute,
      headerColor: 'yellow',
      bgColor: 'white',
      showFooter: false,
      showTabbar: false
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from && from.name) {
        vm.prevRoute = from
      } else {
        vm.prevRoute = "__history_back"
      }
    })
  },
  data() {
    return {
      update_sending: false,
      isFocused: false,
      teamId: null,
      authType: null,
      token: this.$route.query.token || this.$store.state.invite.token,
      teamCode: null,
      publicStatus: null,
      errorMsgList: [],

      inputName         : null,
      inputApprovalFlg  : null,
      approvalFlgOptions: [
        { label: '誰でも参加できる', value: 0, },
        { label: '管理者の承認が必要', value: 1, }
      ],
      inputCoverImage        : null,
      inputCoverName         : null,
      inputBadgeImage        : null,
      inputBadgeName         : null,
      inputTeamColor         : null,
      teamColor              : null,
      teamColorOptions : [
        {label: 'テーマ（職場）', value: '255,160,152' },
        {label: 'テーマ（仕事）', value: '117,181,226' },
        {label: 'テーマ（暮らし）', value: '123,226,147' },
        {label: 'テーマ（その他）', value: '247,222,100' }
      ],
      inputProfile                 : null,
      inputUrl                     : null,
      inputUrlTypes                : [],
      inputMemberCommentAddFlg     : false,
      inputMemberChatAddFlg        : false,
      inputMemberMiniVoteAddFlg    : false,
      inputMemberInviteFlg         : false,
      inputJointAdminCommentAddFlg : 1,
      inputJointAdminChatAddFlg    : 1,
      inputJointAdminMiniVoteAddFlg: 1,
      inputJointAdminInviteFlg     : false,
      inputCommonWord              : null,
      inputOpenFlg                 : false,
      inputDefaultNoticeFlg        : true,
      prevRoute: '',
      inputMenmberViewFlg          : false,
      defaultCoverImage : '',
      defaultBadgeImage : '',
      gotDefaultImage : false,
    }
  },
  setup: function() {
    return { v$: useVuelidate() }
  },
  created: async function() {
    if(this.team_id){
      // 組織の編集権限を確認するAPIコール
      await this.$axios.post('/api/' + this.$constants.API_VERSION + '/screen_auth/team/update', {
        "team_id" : this.team_id,
      }).catch(err => {
        if(err.response.status === 461){
          //461が返却されたときは404を見せる
          this.$router.push('/error/404')
        }
      });

      const team = await this.getTeam()

      this.authType                  = team.auth_type
      this.inputName                 = team.name
      this.inputApprovalFlg          = team.approval_flg
      this.inputCoverImage           = team.cover_image
      this.inputBadgeImage           = team.badge_image
      this.inputTeamColor            = team.team_color
      this.inputProfile              = team.profile
      this.inputUrl                  = team.url
      this.inputMemberCommentAddFlg  = team.member_comment_add_flg
      this.inputMemberChatAddFlg     = team.member_chat_add_flg
      this.inputMemberMiniVoteAddFlg = team.member_mini_vote_add_flg
      this.inputMemberInviteFlg      = team.member_invite_flg
      this.inputJointAdminInviteFlg  = team.joint_admin_invite_flg
      this.inputCommonWord           = team.common_word
      this.inputOpenFlg              = team.open_flg
      this.teamCode                  = team.team_code
      this.inputMenmberViewFlg       = team. member_list_view_type == "user"
      this.publicStatus              = team.public_status

      if(team.url_type==null){
        this.inputUrlTypes = []
      }else if(team.url_type!=null && team.url_type.indexOf(",") != -1){
        this.inputUrlTypes = team.url_type.split(",")
      }else{
        this.inputUrlTypes = new Array(team.url_type)
      }
    }
  },
  validations: function() {
    return {
      inputName: {
        required: helpers.withMessage('チーム名の入力は必須です。', required),
        maxLength: helpers.withMessage('チーム名は最大255文字です。', maxLength(255)),
        $autoDirty: true
      },
      inputApprovalFlg: {
        required: helpers.withMessage('招待形式の選択は必須です。', required),
        $autoDirty: true
      },
      inputTeamColor: {
        required: helpers.withMessage('チームテーマの選択は必須です。', required),
        $autoDirty: true
      },
      inputProfile: {
        required: helpers.withMessage('プロフィールの入力は必須です。', required),
        maxLength: helpers.withMessage('プロフィールは最大1000文字です。', maxLength(1000)),
        $autoDirty: true
      },
      inputUrl: {
        maxLength: helpers.withMessage('URLは最大255文字です。', maxLength(255)),
        url: helpers.withMessage('URLは、有効なURL形式で指定してください。', url),
        $autoDirty: true
      },
      inputCommonWord: {
        required: helpers.withMessage('あいことばの入力は必須です。', required),
        $autoDirty: true
      },
    }
  },
  methods: {
    isFieldErrorMsg,
    hasError,
    hasFieldErrorMsg,

    setCoverImageFile(event) {
      this.inputCoverImage = event[0]
      this.inputCoverName = event[1].name
    },
    setBadgeImageFile(event) {
      this.inputBadgeImage = event[0]
      this.inputBadgeName = event[1].name
    },
    getTeam: async function() {
      let team = {}

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/info', { params: { team_id: this.team_id } })
        .then((res) => {
          team = res.data.value.team
        })

      return team
    },
    startMailer: function() {
      const to_address = `${process.env.VUE_APP_ADMIN_MAIL_TO}`
      const subject    = '公的チームへの申請'
      const body       = '作成したチームの種別を私的チームから公的チームへ変更することを申請致します。'

      location.href = 'mailto:' + to_address + '?subject=' + subject + '&body=' + body;
    },
    onFocus() {
      this.isFocused = true
    },
    onBlur() {
      this.isFocused = false
    },
    cancel: async function(){
      // フォーカス中のsubmitは処理しない
      if (this.isFocused) return
      // 前のページに戻る
      this.$router.go(-1)
    },
    update: async function(){
      // フォーカス中のsubmitは処理しない
      if (this.isFocused) return
      //連投させないようにログイン中フラグを立てておく
      this.update_sending = true
      const result = await this.v$.$validate()

      if(! result){
        this.update_sending = false
        scrollBy(0, document.getElementsByClassName('validate-error')[0].getBoundingClientRect().top)
        return
      }

      let inputUrlTypes = null
      if(this.inputUrlTypes){
        inputUrlTypes = this.inputUrlTypes.join(',')
      }

      let CoverImage = null
      if (this.inputCoverImage){
        CoverImage = this.inputCoverImage.split(',')[1]
      } else {
        CoverImage = 'default'
      }
      let BadgeImage = null
      if (this.inputBadgeImage){
        BadgeImage = this.inputBadgeImage.split(',')[1]
      } else {
        BadgeImage = 'default'
      }

      this.errorMsgList = []

       // inputMenmberViewFlgを登録用に変換
      let MemberListViewType = this.inputMenmberViewFlg? "user" : "admin" ;
      if(this.team_id){
        let post_data = {
            "team_id"                      : this.team_id,
            "name"                         : this.inputName,
            "common_word"                  : this.inputCommonWord,
            "team_color"                   : this.inputTeamColor,
            "profile"                      : this.inputProfile,
            "url"                          : this.inputUrl,
            "url_type"                     : inputUrlTypes,
            "approval_flg"                 : this.inputApprovalFlg,
            "member_comment_add_flg"       : this.inputMemberCommentAddFlg,
            "member_chat_add_flg"          : this.inputMemberChatAddFlg,
            "member_mini_vote_add_flg"     : this.inputMemberMiniVoteAddFlg,
            "member_invite_flg"            : this.inputMemberInviteFlg,
            "joint_admin_comment_add_flg"  : this.inputJointAdminCommentAddFlg,
            "joint_admin_chat_add_flg"     : this.inputJointAdminChatAddFlg,
            "joint_admin_mini_vote_add_flg": this.inputJointAdminMiniVoteAddFlg,
            "joint_admin_invite_flg"       : this.inputJointAdminInviteFlg,
            "member_list_view_type"        : MemberListViewType,
            "open_flg"                     : this.inputOpenFlg,
            "default_notice_flg"           : this.inputDefaultNoticeFlg,
        }
        if (CoverImage != null) {
          post_data['cover_image'] = CoverImage
          post_data['file_cover_name'] = this.inputCoverName
        }
        if (BadgeImage != null) {
          post_data['badge_image'] = BadgeImage
          post_data['file_badge_name'] = this.inputBadgeName
        }
        // 組織情報更新APIコール
        await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/update', post_data).then(() => {
          //ページ遷移
          this.$router.app.$emit('page-refresh', this.team_id, () => this.$router.push('/team/home/' + this.team_id))
          // this.$router.push('/team/home/' + this.team_id)
        }).catch(err => {
          if(err.response.status === 400){
            this.errorMsgList = err.response.data.value.errors;
            setTimeout(function() {
              scrollBy(0, document.getElementsByClassName('validate-error')[0].getBoundingClientRect().top)
            }, 500)
          }
          this.update_sending = false
        });
      }else{
        // 組織登録APIコール
        await this.$axios.post('/api/' + this.$constants.API_VERSION + '/team/registration', {
          "name"                         : this.inputName,
          "common_word"                  : this.inputCommonWord,
          "cover_image"                  : CoverImage,
          "file_cover_name"              : this.inputCoverName  ?? 'default_cover.jpg',
          "badge_image"                  : BadgeImage,
          "file_badge_name"              : this.inputBadgeName ?? 'default_badge.jpg',
          "team_color"                   : this.inputTeamColor,
          "profile"                      : this.inputProfile,
          "url"                          : this.inputUrl,
          "url_type"                     : inputUrlTypes,
          "approval_flg"                 : this.inputApprovalFlg,
          "public_status"                : "personal",
          "member_comment_add_flg"       : this.inputMemberCommentAddFlg,
          "member_chat_add_flg"          : this.inputMemberChatAddFlg,
          "member_mini_vote_add_flg"     : this.inputMemberMiniVoteAddFlg,
          "member_invite_flg"            : this.inputMemberInviteFlg,
          "joint_admin_comment_add_flg"  : this.inputJointAdminCommentAddFlg,
          "joint_admin_chat_add_flg"     : this.inputJointAdminChatAddFlg,
          "joint_admin_mini_vote_add_flg": this.inputJointAdminMiniVoteAddFlg,
          "joint_admin_invite_flg"       : this.inputJointAdminInviteFlg,
          "member_list_view_type"        : MemberListViewType,
          "open_flg"                     : this.inputOpenFlg,
          "default_notice_flg"           : this.inputDefaultNoticeFlg,
          "token"                        : this.token,
        }).then((response) => {
          this.teamId = response.data.value.team_id
          //ページ遷移
          this.$router.app.$emit('page-refresh', this.teamId, () => this.$router.push('/team/home/' + this.teamId))
          // this.$router.push('/team/home/' + this.teamId)
        }).catch(err => {
          if(err.response.status === 400){
            this.errorMsgList = err.response.data.value.errors;
            setTimeout(function() {
              scrollBy(0, document.getElementsByClassName('validate-error')[0].getBoundingClientRect().top)
            }, 500)
          }
          this.update_sending = false
        });
      }
    },
    backButtonAction(back) {
      if (back === '__history_back') {
        this.$router.go(-1)
      } else {
        this.$router.push(back, () => {})
      }
    },

    async getDefaultImage() {
      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/image')
      .then((res) => {
        if (res.data.status == 'success') {
          this.defaultCoverImage = res.data.value.cover
          this.defaultBadgeImage = res.data.value.badge
        }
      }).catch((err) => {
        console.log('Error: ' + err)
        this.defaultCoverImage = ''
        this.defaultBadgeImage = ''
      });
      this.gotDefaultImage = true
    },
  }
}
</script>

<style lang="scss" module>
.bg1 {
  width: 100%;
  background-color: $backGround;

  @include pc() {
    padding-bottom: 50px;// いる？要確認
  }
}
.bg2 {
  background-color: $keyWhite;
  @include pc() {
    width: 980px;
    margin: 0 auto;
  }

  @include mobile() {
    width: 100%;
    margin-bottom: 84px;
  }
}
.contents {
  background-color: $keyWhite;
  width: 375px;
  padding: 30px 0 0 0;
  margin: 0 auto;

  @include mobile() {
    padding: 25px 0 0 0;
  }
}
.edit_footer {
  display: block;
  position: sticky;
  width: 100%;
  height: 85px;

  @include mobile(){
    background: $keyYellow;
    box-sizing: border-box;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}

.team_color {
  position: relative;
  &_thumbnail {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 19px;
    left: 16px;
  }
  &_select {
    select {
      width: 100%;
    }
    &_selected_color {
      select {
        box-sizing: border-box;
        text-indent: 34px;
      }
    }
  }
}

.file_wrap {
  position: relative;
}
.file_input {
  color: $keyGreen;
  text-decoration: underline;
  position: absolute;
  text-align: right;
  right: 34px;
  top: 17px;
  label {
    font-weight: normal;
    text-decoration: underline;
    font-size: 11px;
    cursor: pointer;
  }
}
.cover_image {
  width: 100%;
}
.default_height {
  height: 192px;
}

.check_box {
  margin-bottom: 0 !important;
  padding: 4px 0;
}

.check_box_wrap {
  margin: 8px 0;
}

.description {
  font-size: 14px;
  margin: 0 0 8px;
  &_small {
    font-size: 11px;
  }
  &_indent {
    font-size: 14px;
    padding-left: 2em;
    text-indent: -1em;
  }
  a {
    display: inline-block;
    color: $keyGreen;
    margin-top: 8px;
  }
}
.msg_area {
  width: 100% !important;
  margin-top: 10px;
}
.required {
  color: #FF221F;
  font-size: 11px;
  margin: 0 4px ;
}

.cursor_pointer {
  cursor: pointer;
}

.error_text {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #ff0000;
  white-space: pre-wrap;
}
</style>
